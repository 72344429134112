


























































































































































































































































































































@import url('https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css');
i.feather--eye{
  margin-top: -3px;
}
.btn-links .btn{
  white-space: nowrap;
 span{
    vertical-align: top;
  }
}
input[name="unique_id"] {
  max-width: 150px !important;
}
.copy-link, .link-target{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.link-target{
  max-height: 39px;
}
